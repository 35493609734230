<template>
  <div>
    <b-tabs v-model="tabIndex">
      <b-tab
        @click="onTabChange"
        title="Speaker"
        :title-link-class="linkClass(0)"
      >
        <div>
          <b-row>
            <b-col
              v-for="(item, index) in extraListArray"
              :key="index"
              cols="12"
              md="3"
            >
              <b-card
                no-body
                :img-src="
                  item.extra_info_img ? BASE_URL + item.extra_info_img : ''
                "
                img-alt="Image"
                img-top
              >
                <template #header>
                  <h4 class="mb-0">
                    <b> {{ item.extra_info_name }}</b>
                  </h4>
                  <h6>
                    <b-badge pill variant="warning">{{
                      item.extra_info_type
                    }}</b-badge>
                  </h6>
                </template>

                <b-card-body>
                  <div v-html="item.extra_info_description"></div>

                  <b-button
                    @click="onEditItem(item)"
                    size="sm"
                    block
                    variant="outline-primary"
                    >Edit
                    <b-badge pill variant="info">{{
                      item.extra_info_is_active == "Y" ? "active" : "disabled"
                    }}</b-badge></b-button
                  >

                  <!-- <a href="#" class="card-link">Remove</a> -->
                </b-card-body>
              </b-card>
            </b-col>
            <b-col cols="12" md="3">
              <b-card no-body>
                <template #header>
                  <b-button
                    block
                    @click="onAdd('speaker')"
                    variant="outline-primary"
                  >
                    Add Speaker</b-button
                  >
                </template>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-tab>
      <b-tab
        @click="onTabChange"
        title="Sponsers"
        :title-link-class="linkClass(1)"
      >
        <div>
          <b-row>
            <b-col
              v-for="(item, index) in extraListArray"
              :key="index"
              cols="12"
              md="3"
            >
              <b-card
                no-body
                :img-src="
                  item.extra_info_img ? BASE_URL + item.extra_info_img : ''
                "
                img-alt="Image"
                img-top
              >
                <template #header>
                  <h4 class="mb-0">
                    <b> {{ item.extra_info_name }}</b>
                  </h4>
                  <h6>
                    <b-badge pill variant="warning">{{
                      item.extra_info_type
                    }}</b-badge>
                  </h6>
                </template>

                <b-card-body>
                  <div v-html="item.extra_info_description"></div>

                  <b-button
                    @click="onEditItem(item)"
                    size="sm"
                    block
                    variant="outline-primary"
                    >Edit
                    <b-badge pill variant="info">{{
                      item.extra_info_is_active == "Y" ? "active" : "disabled"
                    }}</b-badge></b-button
                  >

                  <!-- <a href="#" class="card-link">Remove</a> -->
                </b-card-body>
              </b-card>
            </b-col>
            <b-col cols="12" md="3">
              <b-card no-body>
                <template #header>
                  <b-button
                    block
                    @click="onAdd('sponser')"
                    variant="outline-primary"
                  >
                    Add Sponser</b-button
                  >
                </template>
              </b-card>
            </b-col>
          </b-row>
        </div></b-tab
      >
      <b-tab
        @click="onTabChange"
        title="Organisers"
        :title-link-class="linkClass(2)"
      >
        <div>
          <b-row>
            <b-col
              v-for="(item, index) in extraListArray"
              :key="index"
              cols="12"
              md="3"
            >
              <b-card
                no-body
                :img-src="
                  item.extra_info_img ? BASE_URL + item.extra_info_img : ''
                "
                img-alt="Image"
                img-top
              >
                <template #header>
                  <h4 class="mb-0">
                    <b> {{ item.extra_info_name }}</b>
                  </h4>
                  <h6>
                    <b-badge pill variant="warning">{{
                      item.extra_info_type
                    }}</b-badge>
                  </h6>
                </template>

                <b-card-body>
                  <div v-html="item.extra_info_description"></div>

                  <b-button
                    @click="onEditItem(item)"
                    size="sm"
                    block
                    variant="outline-primary"
                    >Edit
                    <b-badge pill variant="info">{{
                      item.extra_info_is_active == "Y" ? "active" : "disabled"
                    }}</b-badge></b-button
                  >

                  <!-- <a href="#" class="card-link">Remove</a> -->
                </b-card-body>
              </b-card>
            </b-col>
            <b-col cols="12" md="3">
              <b-card no-body>
                <template #header>
                  <b-button
                    block
                    @click="onAdd('organiser')"
                    variant="outline-primary"
                  >
                    Add organiser</b-button
                  >
                </template>
              </b-card>
            </b-col>
          </b-row>
        </div></b-tab
      >
    </b-tabs>

    <div>
      <b-modal id="bv-modal-eventinfo" hide-footer>
        <template #modal-title> Event {{ form.extra_info_type }} </template>
        <div>
          <b-form-group label="Image">
            <b-img
              v-bind="mainProps"
              v-if="form.extra_info_img"
              :src="form.extra_info_img ? BASE_URL + form.extra_info_img : ''"
              rounded="circle"
              alt="Circle image"
            ></b-img>
            <b-form-file
              @input="onFileUpload(info_img)"
              v-model="info_img"
              plain
            ></b-form-file>
          </b-form-group>
          <b-form-group label="Name">
            <b-form-input
              v-model="form.extra_info_name"
              id="input-default"
              placeholder="Name"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Description">
            <quill-editor v-model="form.extra_info_description"> </quill-editor
          ></b-form-group>
          <b-form-group label="Status">
            <b-form-checkbox v-model="form.extra_info_is_active" switch>{{
              form.extra_info_is_active == true ? "Active" : "Disabled"
            }}</b-form-checkbox>
          </b-form-group>
        </div>
        <b-button variant="primary" class="mt-3" block @click="onSubmit"
          >Submit</b-button
        >
      </b-modal>
    </div>
  </div>
</template>
<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import {
  AddUpdateEventExtraService,
  GetEventExtraInfoListService,
} from "@/apiServices/MasterServices";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import axios from "axios";
export default {
  data() {
    return {
      BASE_URL: process.env.VUE_APP_BASEURL,
      tabIndex: 0,
      mainProps: {
        blankColor: "#777",
        width: 75,
        height: 75,
        class: "m1 mb-1",
      },
      info_img: null,
      loading: false,
      form: {
        event_id: null,
        extra_info_id: null,
        extra_info_name: null,
        extra_info_description: null,
        extra_info_img: null,
        extra_info_is_active: true,
        extra_info_type: null,
      },
      extraListArray: [],
    };
  },
  components: {
    quillEditor,
  },
  beforeMount() {
    if (this.$route.query && this.$route.query.event_id) {
      this.form.event_id = this.$route.query.event_id;
      this.getExtraInfoList(this.$route.query.event_id);
    } else {
      window.history.go(-1);
    }
  },
  methods: {
    async onTabChange() {
      this.$nextTick(() => {
        this.getExtraInfoList();
      });
    },
    async onSubmit() {
      try {
        this.form.extra_info_is_active =
          this.form.extra_info_is_active == true ? "Y" : "N";
        this.form.extra_info_type =
          this.tabIndex == 0
            ? "speaker"
            : this.tabIndex == 1
            ? "sponser"
            : this.tabIndex == 2
            ? "organiser"
            : null;
        const response = await AddUpdateEventExtraService(this.form);
        if (response.data.status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
          // this.genreListArray = response.data.Records.data;
          this.getExtraInfoList();
          this.$bvModal.hide("bv-modal-eventinfo");
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
          // this.$bvModal.hide("bv-modal-eventinfo");
        }
      } catch (error) {
        //this.$bvModal.hide("bv-modal-eventinfo");
        console.log(error);
      }
    },
    onAdd(extra_info_type) {
      this.form.extra_info_type = extra_info_type;
      this.$bvModal.show("bv-modal-eventinfo");
    },
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["bg-primary", "text-light"];
      } else {
        return ["bg-light", "text-info"];
      }
    },
    async onEditItem(item) {
      Object.keys(item).map((x) => {
        this.form[x] = item[x];
        if (x == "extra_info_is_active") {
          this.form[x] = item[x] == "Y" ? true : false;
        }
      });
      this.$bvModal.show("bv-modal-eventinfo");
    },
    async onFileUpload(uploadVModelValue) {
      this.loading = true;
      try {
        const url = this.BASE_URL + "/admin/uploadimage";
        const formData = new FormData();
        formData.append("image", uploadVModelValue);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
            authorization: sessionStorage.getItem("access_token"),
            "x-access-token": sessionStorage.getItem("access_token"),
          },
        };
        const response = await axios.post(url, formData, config);
        if (response.data.message) {
          this.form.extra_info_img = response.data.path;
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log("Error in UploadFile ", err);
      }
    },

    async getExtraInfoList() {
      try {
        const response = await GetEventExtraInfoListService({
          event_id: this.form.event_id,
          extra_info_type:
            this.tabIndex == 0
              ? "speaker"
              : this.tabIndex == 1
              ? "sponser"
              : this.tabIndex == 2
              ? "organiser"
              : null,
          limit: 10000,
        });

        if (response.data.status) {
          this.extraListArray = response.data.Records.data;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
