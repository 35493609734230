import { render, staticRenderFns } from "./EventOtherInfo.vue?vue&type=template&id=89bc2d32&scoped=true"
import script from "./EventOtherInfo.vue?vue&type=script&lang=js"
export * from "./EventOtherInfo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89bc2d32",
  null
  
)

export default component.exports